<template>
  <message-bubble :isMine="isMine" :message="message" :showPopover="true" :item="item">
    <div v-if="isMine" class="box">
      <div class="msg_text" :class="messagePosition" v-html="domPurifyFun(str)"></div>
      <img src="@/assets/img/icon/right_s.png" alt="" v-if="isMine" />
    </div>
    <div v-else class="box">
      <img src="@/assets/img/icon/left_s.png" alt="" v-if="!isMine" />
      <div class="msg_text" :class="messagePosition" v-html="domPurifyFun(str)"></div>
    </div>
  </message-bubble>
</template>
<script setup>
import { emojiMap, emojiName } from "@/untils/emojiMap";
import { computed, ref, watchEffect } from "vue";
import MessageBubble from "./messageBubble";
const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  isMine: {
    type: Boolean,
  },
  item: {
    type: Object,
    required: true,
  },
});
const str = ref("");
const messagePosition = computed(() => (props.isMine ? "position-right" : "position-left"));
watchEffect(() => {
  str.value = dataImgFilte(props.message);
});
function dataImgFilte(val) {
  var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
  return val
    .replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
      return arrEntities[t];
    })
    .replace(/\[[^\]]+\]/g, function (tmp) {
      var img = `<img src='${emojiMap[tmp]}' style="width: 20px;height: 20px;vertical-align:middle">`;
      return img;
    });
}
</script>

<style scoped>
.box {
  display: flex;
}
.box img {
  width: 6px;
  height: 22px;
  margin-top: 9px;
}

.msg_text {
  max-width: 64.5vw;
  border-radius: 4px;
  padding: 2.4vw;
  font-size: 4.26vw;
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: break-word;
  /* align-items: center;
  display: flex;
  flex-wrap: wrap; */
}
.position-right {
  background: #d8ad6d;
  text-align: left;
}
.position-left {
  background: #fff;
}
</style>
