<template>
  <van-overlay :show="visible" @click.stop="">
    <div class="groupMembers">
      <div class="fixed">
        <div class="back_box">
          <img src="@/assets/img/icon/back.png" alt="" @click.stop="close" />
          <div class="title">群组（{{ props.memberCount }}）</div>
        </div>
      </div>
      <div class="container">
        <div class="search_box">
          <van-search
            v-model="value"
            placeholder="搜索"
            clearable
            @update:model-value="getSearchRes"
            @clear="clearBtn"
          />
        </div>
        <div class="members_list">
          <div class="members_item" v-for="(item, i) in memberList" :key="i">
            <div class="head">
              <img
                :src="filesCdnDomain + item.avatar"
                alt=""
                class="User_aravt"
                v-if="item.avatar"
              />
              <img v-else src="@/assets/img/icon/avart.png" alt="" class="User_aravt" />
              <img src="@/assets/img/icon/fg.png" alt="" class="icon" v-if="item.role == 'Admin'" />
              <img
                src="@/assets/img/icon/owner.png"
                alt=""
                class="icon"
                v-if="item.role == 'Owner'"
              />
            </div>
            <div class="name">{{ item.remark ? item.remark : item.nick }}</div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>
<script setup name="GroupMembers">
import { chat } from "@/untils/tim";
import { ref, watchEffect, computed, onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();
const loading = ref(true);
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const memberList = ref([]);
const allMemberList = ref([]);
const value = ref("");
const emit = defineEmits(["close"]);
const props = defineProps({
  memberCount: {
    type: Number,
    default: 0,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
function getSearchRes() {
  const pattern = new RegExp(value.value, "i");

  let arr = allMemberList.value.filter((item) => {
    return pattern.test(item.nick) || pattern.test(item.remark);
  });
  // console.log(arr);
  memberList.value = uniqueByKey(arr, "userID");
}
function uniqueByKey(array, key) {
  return array.reduce((acc, current) => {
    const x = acc.find((item) => item[key] === current[key]);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
}
function clearBtn() {
  value.value = "";
  memberList.value = allMemberList.value;
}
function close() {
  emit("close");
  memberList.value = [];
  allMemberList.value = [];
  value.value = "";
}
function _getGroupMemberList() {
  chat()
    .getGroupMemberList({
      groupID: localStorage.getItem("imChatId"),
      offset: memberList.value.length,
      count: 100,
    })
    .then((imResponse) => {
      loading.value = false;
      memberList.value = memberList.value.concat(imResponse.data.memberList);

      memberList.value.forEach((item) => {
        getMerberRemarkName(item.userID).then((res) => {
          let _memberList = res.data.friendList.length > 0 ? res.data.friendList[0] : {};
          // console.log(_memberList);
          item.remark = _memberList ? _memberList.remark : "";
        });
      });
      if (imResponse.data.offset > 0) {
        _getGroupMemberList();
      }
      let arr = JSON.parse(JSON.stringify(memberList.value));
      allMemberList.value = arr;
      // console.log(allMemberList.value);
    })
    .catch(() => {
      loading.value = false;
    });
}
function getMerberRemarkName(userID) {
  let promise = chat().getFriendProfile({
    groupID: localStorage.getItem("imChatId"),
    userIDList: [userID], // 请注意：即使只拉取一个群成员的资料，也需要用数组类型，例如：userIDList: ['user1']
  });

  return promise;
}
defineExpose({
  _getGroupMemberList,
});
</script>
<style scoped>
.groupMembers {
  height: 100%;
  background: #fafafa;
}
.container {
  padding-top: 10.66vw;
  padding-bottom: 2.66vw;
  overflow-y: scroll;
  height: calc(100% - 10.66vw);
}
.search_box {
  background-color: #fff;
}
.members_list {
  margin: 2.66vw 2.66vw 0 2.66vw;
  padding-top: 2.66vw;
  display: flex;
  border-radius: 8px;
  flex-wrap: wrap;
  background-color: #fff;
}
.members_item {
  flex-basis: 20%;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.66vw;
}
.head {
  width: 12.53vw;
  height: 12.53vw;
  position: relative;
}
.head .User_aravt {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.icon {
  position: absolute;
  width: 14px;
  height: 14px;
  bottom: 0;
  right: -2px;
}
.name {
  color: rgba(0, 0, 0, 0.45);
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 12.53vw;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
</style>
<style>
.groupMembers .van-search__content {
  align-items: center;
  border-radius: 5.33vw;
  padding: 10px;
}
.groupMembers .van-field__body {
  height: 100%;
}
.groupMembers .van-search__content .van-cell {
  margin-top: 0;
  background-color: transparent;
}
</style>
