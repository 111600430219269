//获取腾讯im签名
import request from "@/untils/request.js";
//获取腾讯im签名 （非游客）
export function getTxImSign(data) {
  return request({
    url: "/live/user/txImUserSign",
    method: "post",
    data,
  });
}
// 获取腾讯im签名 （游客）
export function getTxImSignVisitor(data) {
  return request({
    url: "/live/user/txImSign/visitor",
    method: "post",
    data,
  });
}
//系统消息
export function systemMsgIm(params) {
  return request({
    url: "/live/message/list",
    method: "get",
    params,
  });
}
//获取直播间敏感词屏蔽
export function getSensitiveWords(params) {
  return request({
    url: "/live/config/sensitiveWords",
    method: "get",
    params,
  });
}
