<template>
  <LoadingCom v-if="loading" style="height: 100%" />
  <div class="chat" ref="scroll" v-else>
    <div class="tips" v-if="props.imClose == 1">
      {{ initInfo.roomNotice }}
    </div>
    <div class="msg_box" v-show="props.imClose == 1">
      <div v-for="(item, i) in massageList" :key="i">
        <MsgGift v-if="item.action == 'Gift'" :message="item" />
        <MsgTip :message="item" v-else-if="item.action == 'RoomNotification'" />
        <MsgCar v-else-if="item.action == 'EnterRoom'" :message="item" />
        <MsgDefault
          v-if="item.action == 'RoomMessage'"
          :message="item"
          :content="filterFn(sensitiveWords, item.message)"
        />
      </div>
    </div>
    <SendBox :isMute="isMute" @sendImRoomMsg="sendImRoomMsg" />
    <div class="tips_" v-show="props.imClose == 2">聊天室暂未开启</div>
    <div class="fixed">
      <img src="@/assets/img/live/img.png" alt="" @click="openAssistant" />
    </div>
    <van-overlay :show="visible" @click.stop="">
      <ContactAssistant @close="visible = false" :assistantlist="assistantlist" />
    </van-overlay>
  </div>
</template>
<script setup name="Chat">
import ContactAssistant from "./contactAssistant.vue";
import { filterFn } from "@/untils/index";
import { userPowerLive, anchorAssistantList } from "@/assets/api/living";
import { getTxImSign, getTxImSignVisitor } from "@/assets/api/im";
import { chat } from "@/untils/tim";
import { useRouter } from "vue-router";
import TencentCloudChat from "@tencentcloud/chat";
import SendBox from "./sendBox.vue";
import MsgTip from "./msgTip.vue";
import MsgDefault from "./msgDefault.vue";
import MsgGift from "./msgGift.vue";
import MsgCar from "./msgCar.vue";
import { showConfirmDialog, showDialog } from "vant";
import {
  ref,
  computed,
  watchEffect,
  onMounted,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
} from "vue";
import { openDialog } from "@/untils/index";
import { decryptAES } from "@/untils/cryptoUtils";
import { useStore } from "vuex";
import Bus from "@/untils/event-bus";
const sensitiveWords = computed(() => store.state.imJs.sensitiveWords);
const initInfo = computed(() => store.state.commonJs.initInfo);
const userInfo = computed(() => store.state.userJs.userInfo);
const isLogin = ref(localStorage.getItem("isLogin"));
const store = useStore();
const router = useRouter();
const scroll = ref();
const isMute = ref(false);
const loading = ref(true);
const massageList = ref([]);
const assistantlist = ref([]);
const visible = ref(false);
const _touristId = ref(""); //保存游客id
const _touristName = ref(""); //保存游客name
const roomId = ref();
function openDialogBtn() {
  openDialog();
}
const props = defineProps({
  imClose: {
    type: Number,
    default: 1,
  },
  signature: {
    type: String,
    default: "",
  },
});
onMounted(() => {
  setTimeout(function () {
    loading.value = false;
    if (localStorage.getItem("Token")) {
      _userPowerLive();
    }
    initIm();
  }, 1000);
});
watchEffect(() => {
  if (scroll.value) {
    scroll.value.scrollTop = scroll.value.scrollHeight;
  }
});
function openAssistant() {
  if (!isLogin.value) {
    showConfirmDialog({
      title: "提示",
      message: "未登录，是否先登录",
    })
      .then(() => {
        router.push({
          name: "Login",
        });
      })
      .catch(() => {});
    return;
  }
  anchorAssistantList({
    anchorId: router.currentRoute.value.query.id,
  }).then((res) => {
    visible.value = true;
    assistantlist.value = res.data;
  });
}
onBeforeMount(() => {
  roomId.value = router.currentRoute.value.query.id;
  if (!localStorage.getItem("Token")) {
    //游客身份
    let _dateP = Date.parse(new Date()).toString().slice(1, 4) //缓存时间戳
    let _id = "GUEST_" + "h5_" + randomCoding() + _dateP;
    if (!localStorage.getItem("touristID")) {
      localStorage.setItem("touristID", _id);
    } else {
      _id = localStorage.getItem("touristID");
    }
    //如果没有 生成唯一的用户ID来做im登录  游客模式
    getTxImSignVisitor({ imUid: _id }).then((result) => {
      // 解码
      let txim = decryptAES(result.data);
      _touristId.value = txim.imId;
      _touristName.value = randomCoding() + _dateP;
      chat()
        .login({
          userID: txim.imId,
          userSig: txim.txImSign,
        })
        .then((result) => {
          imReady();
        })
        .catch((err) => {});
    });
  }
});

function initIm() {
  if (localStorage.getItem("Token")) {
    imReady();
  }
  ///im事件监听
  chat().on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, receiveMsg);
}
function receiveMsg(e) {
  e.data.forEach((item) => {
    // console.log(item);
    if (!item.payload.data) return;
    const data = JSON.parse(item.payload.data);

    if (data.action === "RoomNotification") {
      let type = data.data.type;
      data.message =
        type == "RoomNotifyTypeCancelManager" ? `房管权限已被取消` : `被设置为房间管理员`;

      showMsg(data);
    }
    //直播已结束
    if (data.action == "LiveFinished") {
      showDialog({
        title: "提示",
        message: "该直播已经结束了",
      }).then(() => {
        router.replace({
          name: "Home",
        });
      });
    }
    //房间消息
    if (data.action == "RoomMessage") {
      if (userInfo.value && data.sender.id === userInfo.value.uid) {
        return;
      }
      // 是以visitor_开头的游客发消息不显示
      if (data.message != "进入直播间" && item.from.startsWith("visitor_") == true) {
        // 游客账号且发的其他消息
        console.log("垃圾信息");
        console.log("垃圾信息的人", data.message, item.from);
      } else {
        showMsg(data);
      }
    }
    //进入房间提示，以及贵族飘屏处理
    if (data.action == "EnterRoom") {
      showMsg(data);
    }

    //礼物消息
    if (data.action === "Gift") {
      data.message = "送出" + data.gift.giftCount + "个" + data.gift.giftTitle;
      showMsg(data);
    }
    //敏感词  推送
    if (data.action === "SensitiveWordsUpdate") {
      let _sensitiveWords = data.data.sensitiveWords;
      let arr = _sensitiveWords.split(",");
      nextTick(() => {
        sensitiveWords.value = arr;
      });
    }
    //房间热度推送
    if (data.action === "LiveHot") {
      if (data.data.liveHot.anchorId == router.currentRoute.value.query.id) {
        Bus.$emit("getNewHot", data.data.liveHot.hot);
      }
    }
  });
}
function imReady() {
  // console.log(1454454646546);
  chat()
    .joinGroup({ groupID: "LIVEROOM_" + router.currentRoute.value.query.id })
    .then((imResponse) => {
      if (
        imResponse.data.status == TencentCloudChat.TYPES.JOIN_STATUS_SUCCESS ||
        imResponse.data.status == TencentCloudChat.TYPES.JOIN_STATUS_ALREADY_IN_GROUP
      ) {
        //在没有被禁言的时候，并且不是主播进入直播间发送 进入直播间的信息
        // if (
        //   userInfo.value &&
        //   userInfo.value.uid &&
        //   userInfo.value.uid != router.currentRoute.value.query.id &&
        //   !isMute.value
        // ) {
        //   sendMsgFunc("进入直播间", "EnterRoom");
        // }
        sendMsgFunc("进入直播间", "EnterRoom");
      }
    })
    .catch((err) => {});
}
/**查询我的直播间权限 */
function _userPowerLive() {
  userPowerLive({
    anchorId: router.currentRoute.value.query.id,
    uid: userInfo.value.uid,
  }).then((ressult) => {
    if (!ressult.data) return;
    if (ressult.data.isMute == 1) {
      //被禁言
      isMute.value = true;
    }
  });
}
function sendImRoomMsg(value) {
  sendMsgFunc(value, "RoomMessage");
}
//消息体处理
function showMsg(content) {
  // console.log(content)
  massageList.value.push({
    ...content.sender,
    ...content,
    isVip:
      content.sender.vipLevel &&
      content.sender.vipDate &&
      new Date(content.sender.vipDate).getTime() > Date.now(),
  });

  nextTick(() => {
    setTimeout(function () {
      if (scroll.value) {
        scroll.value.scrollTop = scroll.value.scrollHeight;
      }
    }, 10);
  });
}
//发送消息的函数
function sendMsgFunc(msg, action) {
  let _role = 4; //1 群主，2是管理员，3群成员
  if (userInfo.uid == router.currentRoute.value.query.id) {
    _role = 1;
  }
  const content = {
    action: action,
    sender: {
      id: userInfo.value.uid,
      nickName: userInfo.value.nickName ? userInfo.value.nickName : "游客" + _touristName.value,
      avatar: userInfo.value.avatar ? userInfo.value.avatar : "",
      userLevel: userInfo.value.userLevel ? userInfo.value.userLevel : 1,
      vipLevel: userInfo.value.vipLevel ? userInfo.value.vipLevel : 0,
      vipDate: userInfo.value.vipDate ? userInfo.value.vipDate : "",
      anchorLevel: userInfo.value.anchorLevel,
      //1 群主，2是管理员，3群成员
      role: userInfo.value.uid ? _role : 0,
    },
    message: msg,
    drawDanmuColor: "#ffffff",
  };

  const msgBody = chat().createCustomMessage({
    to: "LIVEROOM_" + router.currentRoute.value.query.id,
    conversationType: TencentCloudChat.TYPES.CONV_GROUP,
    payload: {
      data: JSON.stringify(content),
    },
  });

  chat()
    .sendMessage(msgBody)
    .then((result) => {
      showMsg(content);
    })
    .catch((e) => {
      // localStorage.removeItem("isLogin");
      // store.commit("UPDATE_USER_INFO", {});
      // store.commit("toggleAnchor", false);
      // store.commit("changeUserIdentity", 1);
      // store.commit("changYouliaoExpert", 0);
      // showConfirmDialog({
      //   title: "提示",
      //   message: "消息发送失败，请检查您的账号是否在其他地方登录了",
      // })
      //   .then(() => {
      //     location.href = "/home";
      //   })
      //   .catch(() => {
      //     location.href = "/home";
      //   });
    });
}
function randomCoding() {
  //创建26个字母数组
  var arr = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  var idvalue = "";
  let n = 2; //这个值可以改变的，对应的生成多少个字母，根据自己需求所改
  for (var i = 0; i < n; i++) {
    idvalue += arr[Math.floor(Math.random() * 26)];
  }
  return idvalue;
}
onBeforeUnmount(() => {
  if (userInfo.value.uid && userInfo.value.uid == roomId.value) {
    //是主播 不退群
    return;
  }
  chat()
    .quitGroup("LIVEROOM_" + roomId.value)
    .then((res) => {
      // console.log("已退出该直播群");
    })
    .catch((err) => {
      console.log(err);
    });
});
</script>
<style scoped>
.tips_ {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: black !important;
}
.chat {
  overflow-y: scroll;
  height: 100%;
}
.chat::-webkit-scrollbar {
  display: none;
}
.tips {
  color: #dea97f;
  font-family: PingFang SC;
  font-size: 3.2vw;
  text-align: left;
  font-weight: 400;
  line-height: 4.8vw;
  margin: 3.2vw 2.93vw 2.93vw;
}
.msg_box {
  margin: 0 3.73vw;
  padding-bottom: 25vw;
}
.fixed {
  position: fixed;
  bottom: 15.46vw;
  right: 8px;
  z-index: 9;
}
.fixed img {
  height: 16vw;
  width: auto;
}
.van-overlay {
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
