<template>
  <div class="grounpNotice">
    <div class="back_box">
      <img src="@/assets/img/icon/back.png" alt="" @click="goBack" />
      <div class="title">群公告</div>
      <div class="save" @click="saveHandle">
        {{ !showText ? "编辑" : "完成" }}
      </div>
    </div>
    <div class="form">
      <van-cell-group inset>
        <van-field
          @update:model-value="changeNotice"
          v-model="message"
          type="textarea"
          placeholder="暂无公告"
        />
      </van-cell-group>
    </div>
  </div>
</template>
<script setup name="GrounpNotice">
import { chat } from "@/untils/tim";
import { showFailToast, showSuccessToast } from "vant";
import { nextTick, ref, watch, watchEffect } from "vue";
const emit = defineEmits(["close"]);
const props = defineProps({
  notification: {
    type: String,
    default: 0,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const showText = ref(false);
const message = ref("");
function goBack() {
  emit("close");
  message.value = "";
  showText.value = false;
}
function saveHandle() {
  if (!showText.value) return;
  chat()
    .updateGroupProfile({
      groupID: localStorage.getItem("imChatId"),
      notification: message.value,
    })
    .then((res) => {
      showSuccessToast("修改成功");
      goBack();
    })
    .catch((err) => {
      showFailToast("修改失败");
    });
}
function changeNotice() {
  showText.value = true;
}
watchEffect(() => {
  if (props.visible) {
    nextTick(() => {
      message.value = props.notification;
    });
  }
});
</script>
<style scoped>
.grounpNotice {
  min-height: 100vh;
  background: #fff;
  padding: 0 14px;
}
.back_box {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
}
.save {
  opacity: 1 !important;
  color: #d8ad6d !important;
}

.form {
  padding: 10.66vw 0 0 0;
  height: 80vh;
}
</style>
<style>
.van-cell {
  background: #fafafa;
  border-radius: 8px;
  height: 100%;
  margin-top: 10px;
}
</style>
