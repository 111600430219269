<template>
  <div class="selectPerson">
    <div class="fixed">
      <div class="back_box">
        <img src="@/assets/img/icon/back.png" alt="" @click.stop="close" />
        <div class="title">选择提醒的人</div>
      </div>
    </div>
    <div class="container">
      <div class="search_box">
        <van-search
          v-model="searchKey"
          placeholder="搜索群成员"
          @input="getSearchRes"
          @clear="clearBtn"
        />
      </div>
      <!-- 群主和群管理有所有人选项，普通用户没有 -->
      <div class="all_person" v-if="myIdentity != 'Member'" @click="selectAll">
        <img src="@/assets/img/icon/icon11.png" alt="" class="head" />全体成员
      </div>
      <van-list class="list_box" @load="onLoad" :finished="finished" v-model:loading="loading">
        <div v-for="(item, i) in memberList" :key="i">
          <div class="bar_tetx_box">
            <div class="bar_tetx" @click="selectOne(item)">
              <div class="head">
                <img
                  :src="filesCdnDomain + item.avatar"
                  alt=""
                  class="head_img"
                  v-if="item.avatar"
                />
                <img v-else src="@/assets/img/icon/avart.png" alt="" class="head_img" />
                <img
                  src="@/assets/img/icon/fg.png"
                  alt=""
                  class="icon"
                  v-if="item.role == 'Admin'"
                />
                <img
                  src="@/assets/img/icon/owner.png"
                  alt=""
                  class="icon"
                  v-if="item.role == 'Owner'"
                />
              </div>

              <span class="nick"> {{ item.nick }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script setup name="SelectPerson">
import { watchEffect, ref, computed } from "vue";
import { useStore } from "vuex";
import { chat } from "@/untils/tim";
const emit = defineEmits(["close", "handlePickUser"]);
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const userInfo = computed(() => store.state.userJs.userInfo);
const store = useStore();
const loading = ref(true);
const finished = ref(false);
const allMemberList = ref([]);
const memberList = ref([]);
const searchKey = ref("");
const myIdentity = ref("");
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});
function getSearchRes() {
  memberList.value = allMemberList.value.filter((item) => item.nick.indexOf(searchKey.value) > -1);
}
function clearBtn() {
  searchKey.value = "";
  memberList.value = allMemberList.value;
}
function selectOne(item) {
  let user = {
    name: item.nick,
    id: item.userID,
  };
  allMemberList.value = [];
  memberList.value = [];
  emit("handlePickUser", user);
}
function selectAll() {
  let user = {
    name: "所有人",
    id: "allUser",
  };
  emit("handlePickUser", user);
}
function close() {
  emit("close");
  searchKey.value = "";
  allMemberList.value = [];
  memberList.value = [];
}
function onLoad() {
  if (!finished.value) {
    _getGroupMemberList();
  }
}
function _getGroupMemberList() {
  chat()
    .getGroupMemberList({
      groupID: localStorage.getItem("imChatId"),
      offset: memberList.value.length,
      count: 100,
    })
    .then((imResponse) => {
      loading.value = false;
      if (imResponse.data.offset == 0) {
        finished.value = true;
      }
      let arr = imResponse.data.memberList.filter((item) => item.userID != userInfo.value.uid);
      memberList.value = memberList.value.concat(arr);
      allMemberList.value = memberList.value;
    })
    .catch(() => {
      loading.value = false;
    });
}
function _getGroupProfile() {
  chat()
    .getGroupProfile({
      groupID: localStorage.getItem("imChatId"),
    })
    .then((imResponse) => {
      myIdentity.value = imResponse.data.group.selfInfo.role;
    });
}
watchEffect(() => {
  if (props.visible) {
    _getGroupMemberList();
    _getGroupProfile();
  }
});
</script>

<style scoped>
.selectPerson {
  height: 100vh;
  background: #fafafa;
  position: relative;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}
.container {
  padding-top: 10.66vw;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.search_box {
  background-color: #fff;
  height: 30px;
  padding: 0 14px;
  margin: 4px 0 14px 0;
}
.all_person {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  height: 14.4vw;
  padding: 0 3.73vw;
  margin: 10px 3.73vw 0 3.73vw;
  color: #000;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
}

.list_box {
  flex: 1;
  padding: 0px 3.73vw 10.66vw;
  overflow-y: scroll;
}
.bar_tetx_box {
  padding: 0 2.66vw;
  background: #fff;
  border-radius: 8px;
}
.bar_tetx {
  display: flex;
  height: 14.4vw;
  align-items: center;
  justify-content: flex-start;
}

.head {
  width: 10.13vw;
  height: 10.13vw;
  margin-right: 8px;
  border-radius: 50%;
  position: relative;
}
.head_img {
  width: 10.13vw;
  height: 10.13vw;
  border-radius: 50%;
  object-fit: cover;
}
.icon {
  position: absolute;
  width: 14px;
  height: 14px;
  bottom: 0;
  right: -2px;
}
.nick {
  color: #000;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
}
</style>
<style>
.selectPerson .van-cell {
  padding: 0 !important;
}
.selectPerson .van-search {
  padding: 0 !important;
}
.selectPerson .van-search__content {
  align-items: center;
  background: #f6f7fa !important;
  border-radius: 5.33vw;
}
.selectPerson .van-field__body {
  height: 100%;
}
.van-index-bar__index {
  line-height: 4.53vw;
  font-size: 3.2vw;
  font-weight: 500;
}

.van-index-anchor {
  text-align: left;
  color: #a9a9a9;
  font-weight: 400;
  height: 10.66vw;
  line-height: 10.66vw;
}
.van-index-anchor--sticky {
  background: #fafafa !important;
}
</style>
