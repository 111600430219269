export function format(date, fmt) {
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  //(y+)匹配多个y，比如yyyy
  if (/(y+)/.test(fmt)) {
    // RegExp.$1是RegExp的一个属性,指的是与正则表达式匹配的第一个 子匹配(以括号为标志)字符串
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}
export function getWeek(num) {
  let week = "";
  switch (num) {
    case 0:
      week = "星期日";
      break;
    case 1:
      week = "星期一";
      break;
    case 2:
      week = "星期二";
      break;
    case 3:
      week = "星期三";
      break;
    case 4:
      week = "星期四";
      break;
    case 5:
      week = "星期五";
      break;
    default:
      week = "星期六";
  }
  return week;
}
export function copyDomText(val) {
  const text = val;
  // 添加一个input元素放置需要的文本内容
  const input = document.createElement("input");
  input.value = text;
  document.body.appendChild(input);
  // 选中并复制文本到剪切板
  input.select();
  document.execCommand("copy");
  // 移除input元素
  document.body.removeChild(input);
}
import { showConfirmDialog } from "vant";

export function openDialog() {
  showConfirmDialog({
    message: "下载APP获得更多功能，可投屏电视，尽享高清直播！",
    confirmButtonText: "下载APP",
    confirmButtonColor: "#D8AD6D",
    cancelButtonColor: "#9CA3AF",
  })
    .then(() => {
      location.href = "/downLoad";
    })
    .catch(() => {
      // on cancel
    });
}
export function imTime(time) {
  let date = new Date(time * 1000);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let M = date.getMinutes();
  M = M < 10 ? "0" + M : M;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;

  let timeValue = new Date(time * 1000).getTime();
  let timeNew = new Date().getTime();
  var timeDiffer = timeNew - timeValue; // 与当前时间误差s
  var returnTime = "";
  if (timeDiffer <= 60000) {
    // 一分钟内
    returnTime = "刚刚";
  } else if (timeDiffer > 60000 && timeDiffer < 3600000) {
    // 1小时内
    returnTime = h + ":" + M;
  } else if (timeDiffer >= 3600000 && timeDiffer < 86400000 && isYestday(timeValue) === false) {
    // 今日
    returnTime = h + ":" + M;
  } else if (timeDiffer > 3600000 && isYestday(timeValue) === true) {
    // 昨天
    returnTime = "昨天" + " " + h + ":" + M;
  } else if (
    timeDiffer > 86400000 &&
    isYestday(timeValue) === false &&
    isYear(timeValue) === true
  ) {
    // 今年
    returnTime = m + "-" + d + " " + h + ":" + M;
  } else {
    // 不属于今年
    returnTime = y + "-" + m + "-" + d + " " + h + ":" + M;
  }

  return returnTime;
}
function isYestday(timeValue) {
  const date = new Date(timeValue);
  const today = new Date();

  if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth()) {
    if (today.getDate() - date.getDate() === 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
// 是否为今年
function isYear(timeValue) {
  const dateyear = new Date(timeValue).getFullYear();
  const toyear = new Date().getFullYear();
  // console.log(dateyear, toyear)
  if (dateyear === toyear) {
    return true;
  } else {
    return false;
  }
}
export function filterFn(arr, v) {
  let filteredInput = v;
  for (let i = 0; i < arr.length; i++) {
    const sensitiveWord = arr[i];
    const regex = new RegExp(sensitiveWord, "gi");
    filteredInput = filteredInput.replace(regex, "*".repeat(sensitiveWord.length));
  }
  return filteredInput;
}
export function getHotNum(num) {
  if (!num) {
    return 0;
  } else if (num < 10000) {
    return num;
  } else {
    return (num / 10000).toFixed(1) + "万";
  }
}
export function noble(vipLevel) {
  switch (vipLevel) {
    case 1:
      return require("@/assets/img/noble/noble.png");

    case 2:
      return require("@/assets/img/noble/noble1.png");

    case 3:
      return require("@/assets/img/noble/noble2.png");

    case 4:
      return require("@/assets/img/noble/noble3.png");

    case 5:
      return require("@/assets/img/noble/noble4.png");

    default:
      return require("@/assets/img/noble/noble.png");
  }
}

export function domPurifyFun(msg) {
  const clean = DOMPurify.sanitize(msg);
  return clean;
}
