<template>
  <div class="msgDefault">
    <img
      src="@/assets/img/icon/administrators.png"
      v-if="props.message.role == 2"
      alt=""
      class="administrators"
    />
    <img
      src="@/assets/img/icon/an_icon.png"
      alt=""
      class="administrators"
      v-if="props.message.role == 1"
    />
    <img :src="noble(props.message.vipLevel)" alt="" v-if="props.message.isVip" class="noble" />
    <img class="level" :src="lLevel[props.message.userLevel * 1]" />
    <span class="name">{{ props.message.nickName }}：</span>
    <span
      class="d_text"
      v-html="domPurifyFun(str)"
      :style="{ color: _getColor(props.message.drawDanmuColor) }"
    ></span>
  </div>
</template>
<script setup name="msgDefault">
import { emojiMap, emojiName } from "@/untils/emojiMap";
import { lLevel } from "@/untils/level-icon";
import { ref, watchEffect } from "vue";
import { noble } from "@/untils/index";
const props = defineProps({
  message: {
    type: Object,
    default: {},
  },
  content: {
    type: String,
    default: "",
  },
});
const str = ref("");
watchEffect(() => {
  // console.log(props.message);
  str.value = dataImgFilte(props.content);
});
function dataImgFilte(val) {
  var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
  return val
    .replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
      return arrEntities[t];
    })
    .replace(/\[[^\]]+\]/g, function (tmp) {
      var img = `<img src='${emojiMap[tmp]}' style="width: 20px;height: 20px;vertical-align: middle ;">`;
      return img;
    });
}
function _getColor(color) {
  if (!color) {
    return "rgba(0, 0, 0, 0.9)";
  }
  let lowerStr = color.toLowerCase();
  if (lowerStr == "#fff" || lowerStr == "#ffffff" || lowerStr == "white") {
    return "rgba(0, 0, 0, 0.9)";
  } else {
    return color;
  }
}
</script>
<style scoped>
.msgDefault {
  text-align: left;
  margin-bottom: 1.6vw;
  word-break: break-all;
}
.administrators {
  width: 10.13vw;
  margin-right: 2px;
  vertical-align: middle;
}
.noble {
  width: 6.4vw;
  height: auto;
  margin-right: 2px;
  vertical-align: middle;
}
.level {
  width: 10.66vw;
  height: auto;
  vertical-align: middle;
}
.name {
  color: #6898df;
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
  line-height: 5.86vw;
}
.d_text {
  color: rgba(0, 0, 0, 0.9);
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
  line-height: 5.86vw;
  overflow-wrap: break-word;
}
</style>
