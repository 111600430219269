<template>
  <div class="tab-bar">
    <router-link to="/" exact-active-class="active">
      <img src="../assets/img/tab/home.png" alt="" class="default" />
      <img src="../assets/img/tab/home_a.png" alt="" class="active_img" />
      <p>首页</p>
    </router-link>
    <router-link to="/match" active-class="active">
      <img src="../assets/img/tab/match.png" alt="" class="default" />
      <img src="../assets/img/tab/match_a.png" alt="" class="active_img" />
      <p>赛事</p>
    </router-link>
    <a
      @click="goPage"
      :class="{
        active: router.currentRoute.value.path == '/msg',
      }"
      class="msg_tab"
    >
      <img src="../assets/img/tab/msg.png" alt="" class="default" />
      <img src="../assets/img/tab/msg_a.png" alt="" class="active_img" />
      <p>消息</p>
      <span class="span" v-if="unreadCount > 0"> {{ unreadCount > 99 ? "99+" : unreadCount }}</span>
    </a>
  </div>
</template>

<script setup name="tabbar">
import { chat } from "@/untils/tim";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, onMounted, ref, watchEffect, watch } from "vue";
import { showConfirmDialog } from "vant";
import TencentCloudChat from "@tencentcloud/chat";
const router = useRouter();
const isLogin = ref(localStorage.getItem("isLogin"));
const unreadCount = ref(0);
const store = useStore();
const path = ref("");
function _getTotalUnreadMessageCount() {
  let totalUnreadCount = chat().getTotalUnreadMessageCount();
  unreadCount.value = totalUnreadCount;
  store.commit("changeUnreadCount", totalUnreadCount);
}
function onTotalUnreadMessageCountUpdated(event) {
  // console.log("监听会话更新"+event.data);
  unreadCount.value = event.data;
  store.commit("changeUnreadCount", event.data);
}
watch(
  () => localStorage.getItem("isLogin"),
  (val) => {
    // console.log(val);
    // console.log("isLoginisLoginisLogin");
    if (!val) {
      chat().off(
        TencentCloudChat.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED,
        onTotalUnreadMessageCountUpdated
      );
    }
  }
);
onMounted(() => {
  if (localStorage.getItem("isLogin")) {
    // console.log("isLoginisLoginisLogin***********");
    //未读总数
    chat().on(
      TencentCloudChat.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED,
      onTotalUnreadMessageCountUpdated
    );
    setTimeout(() => {
      chat()
        .getConversationList()
        .then((res) => {
          // console.log(res);
          // chat().setMessageRead({conversationID: 'C2Cadmin'})
          _getTotalUnreadMessageCount();
        });
    }, 1000);
  }
});

function goPage() {
   router.push({
      name: "Msg",
    });
  // if (!isLogin.value) {
  //   showConfirmDialog({
  //     title: "提示",
  //     message: "未登录，是否先登录",
  //   })
  //     .then(() => {
  //       router.push({
  //         name: "Login",
  //       });
  //     })
  //     .catch(() => {
  //       // on cancel
  //     });
  //   return;
  // } else {
  //   router.push({
  //     name: "Msg",
  //   });
  // }
}
</script>

<style scoped>
.tab-bar {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 14.93vw;
  display: flex;
  align-items: center;
  border-top: 1px solid #f4f4f4;
  background: #fff;
  z-index: 99;
}
.tab-bar img {
  width: 7.46vw;
  height: 7.46vw;
}
.default {
  display: inline;
}
.active_img {
  display: none;
}
.active {
  color: #d8ad6d;
}
.active .default {
  display: none;
}
.active .active_img {
  display: inline;
}

a {
  font-weight: 500;
  font-size: 10px;
  color: #abb0c0;
  text-decoration: none;
  flex: 1;
  text-align: center;
}
.msg_tab {
  position: relative;
}
.span {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 10px;
  left: calc(50% + 2.73vw);
  display: inline-block;
  border-radius: 100%;
  background-color: #ef4444;
  width: 18px;
  height: 18px;
  line-height: 18px;
}
</style>
