<template>
  <div class="editNickName">
    <div class="back_box">
      <img src="@/assets/img/icon/back.png" alt="" @click.stop="close" />
      <div class="title">修改群聊名称</div>
      <div class="save" @click="saveHandle" :class="active ? 'active' : ''">保存</div>
    </div>
    <div class="form">
      <van-cell-group inset>
        <van-field
          @update:model-value="changeNotice"
          v-model="value"
          autosize
          maxlength="12"
          placeholder="请输入群聊名称"
          show-word-limit
        />
      </van-cell-group>
    </div>
  </div>
</template>
<script setup name="EditNickName">
import { chat } from "@/untils/tim";
import { showFailToast, showSuccessToast } from "vant";
import { nextTick, ref, watch, watchEffect } from "vue";
const emit = defineEmits(["close"]);
const value = ref("");
const active = ref(false);
const props = defineProps({
  name: {
    type: String,
    default: 0,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
function changeNotice() {
  active.value = true;
}
function close() {
  emit("close");
  value.value = "";
}
function saveHandle() {
  if (!active.value) return;
  chat()
    .updateGroupProfile({
      groupID: localStorage.getItem("imChatId"),
      name: value.value,
    })
    .then((res) => {
      showSuccessToast("修改成功");

      close();
    })
    .catch((err) => {
      showFailToast("修改失败");
    });
}
watchEffect(() => {
  if (props.visible) {
    nextTick(() => {
      value.value = props.name;
    });
  }
});
</script>
<style scoped>
.editNickName {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.form {
  margin: 10px 14px;
}
</style>
<style scoped>
.editNickName .van-field {
  border-radius: 8px;
  background: #fafafa;
}
.editNickName /deep/ .van-field__word-num {
  color: #d8ad6d;
}
</style>
