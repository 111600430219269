<template>
  <div class="GroupTipPayload">
    <div class="messageRedEnvelope" v-if="props.item.isRevoked">

      <span v-if="props.item.flow == 'out'">你</span>
      <span v-else-if="imChatType== 'GROUP'">"{{ props.item.nick }}"</span>
      <span v-else>对方</span>
      <span>撤回了一条消息</span>
    </div>

    <div
      class="messageRedEnvelope"
      v-if="
        props.item.payload.operationType == 6 &&
        props.item.type == TencentCloudChat.TYPES.MSG_GRP_SYS_NOTICE
      "
    >
      <span> 这是新的群聊 </span>
    </div>
    <div
      class="messageRedEnvelope"
      v-if="
        props.item.payload.operationType == 6 &&
        props.item.type == TencentCloudChat.TYPES.MSG_GRP_TIP
      "
    >
      <span v-if="props.item.payload.newGroupProfile.muteAllMembers"
        >{{ props.item.nick }} 设置了群成员全体禁言</span
      >
      <span v-if="props.item.payload.newGroupProfile.muteAllMembers == false"
        >{{ props.item.nick }} 取消群成员全体禁言</span
      >
      <span v-if="props.item.payload.newGroupProfile.groupName"
        >{{ props.item.nick }} 修改了群名称</span
      >

      <span v-if="props.item.payload.newGroupProfile.notification"
        >{{ props.item.nick }} 修改了群公告</span
      >
       <span v-if="props.item.payload.newGroupProfile.inviteOption"
        >有成员退出了群聊</span
      >
    </div>
    <!-- <div v-if="props.item.payload.operationType == 16" class="messageRedEnvelope">
      <span
        v-if="
          props.item.payload.groupProfile.to == userInfo.uid && props.item.payload.muteTime == 0
        "
        >您被取消了禁言</span
      >
    </div> -->
    <!-- <div
      class="messageRedEnvelope"
      v-if="props.item.payload.operationType == 7 && props.item.payload.memberList.length > 0"
    >
      <span
        v-if="
          props.item.payload.memberList[item.payload.memberList.length - 1].userID != userInfo.uid
        "
      >
        群成员{{ props.item.payload.memberList[item.payload.memberList.length - 1].nick }}</span
      >
      <span v-else>您</span>
      <span
        >被{{
          props.item.payload.memberList[item.payload.memberList.length - 1].muteTime == 0
            ? "取消了禁言"
            : "禁言了"
        }}
      </span>
    </div> -->
    <div
      class="messageRedEnvelope"
      v-if="props.item.payload.operationType == 1 && props.item.payload.memberList.length > 0"
    >
      <span> {{ getTetx(props.item.payload.memberList) }}进入群聊了 </span>
    </div>
    <div
      class="messageRedEnvelope"
      v-if="props.item.payload.operationType == 2 && props.item.payload.userIDList.length > 0"
    >
      <span> {{ props.item.nick }} 退出了群聊 </span>
    </div>
    <div
      class="messageRedEnvelope"
      v-if="props.item.payload.operationType == 3 && props.item.payload.memberList.length > 0"
    >
      <span> {{ getTetx(props.item.payload.memberList) }}被移出聊天群 </span>
    </div>
    <div
      class="messageRedEnvelope"
      v-if="props.item.payload.operationType == 4 && props.item.payload.memberList.length > 0"
    >
      <span> {{ getTetx(props.item.payload.memberList) }}被设置成了管理员 </span>
    </div>
    <div
      class="messageRedEnvelope"
      v-if="props.item.payload.operationType == 5 && props.item.payload.memberList.length > 0"
    >
      <span> {{ getTetx(props.item.payload.memberList) }}被取消管理员身份 </span>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted ,ref} from "vue";
import { useStore } from "vuex";
import TencentCloudChat from "@tencentcloud/chat";
const store = useStore();
const userInfo = computed(() => store.state.userJs.userInfo);
const props = defineProps({
  item: Object,
});

function getTetx(arr) {
  let str = "";
  arr.forEach((element) => {
    str = str + element.nick + ",";
  });
  return str;
}
const imChatType = ref('')
onMounted(()=>{
imChatType.value= localStorage.getItem("imChatType")
})
</script>
<style scoped>
.systemTime {
  color: #9ca3af;
  padding: 4px 6px;
  margin-bottom: 10px;
}
.messageRedEnvelope {
  color: #9ca3af;
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
