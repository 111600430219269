<template>
  <div class="empty" v-if="!isLogin">
    <img src="@/assets/img/home/empty1.png" alt="" class="empty_img" />
    <div class="empty_text">您还未登陆，无法查看消息</div>
    <div class="login-btn" @click="goLogin">立即登陆</div>
  </div>
  <div class="msg_details" v-else>
    <div class="head">
      <img src="../../assets/img/icon/back.png" alt="" @click="goback()" class="back_icon" />
      <!-- 单聊时，若有备注名称显示备注名称 -->
      <div class="msg_name" v-if="imChatType == ''"></div>
      <div class="msg_name" v-if="imChatType == 'GROUP' && conversationProfileInfo.groupProfile">
        {{ conversationProfileInfo.groupProfile.name }}
      </div>
      <div class="msg_name" v-else-if="imChatType == 'C2C'">
        {{
          conversationProfileInfo.remark
            ? conversationProfileInfo.remark
            : conversationProfileInfo.userProfile
            ? conversationProfileInfo.userProfile.nick
            : " "
        }}
      </div>

      <div class="right">
        <!-- 关注按钮  单聊显示 -->
        <div
          class="follow_btn"
          @click.stop="followBtn"
          v-if="imChatType == 'C2C' && basicUserInfo.isAttented == 0"
        >
          <img src="../../assets/img/icon/follow.png" alt="" />关注
        </div>
        <div
          class="followed_btn"
          @click.stop="cancleFollow"
          v-if="imChatType == 'C2C' && basicUserInfo.isAttented == 1"
        >
          已关注
        </div>
        <img src="@/assets/img/icon/more2.png" alt="" @click="goInfo" class="more" />
      </div>
    </div>
    <MessageContainer
      @getAllProhibitioValue="_getAllProhibitioValue"
      @getUserProhibitioValue="_getUserProhibitioValue"
    />
    <div class="send_box">
      <MessageSendBox
        :allProhibitioValue="allProhibitioValue"
        :userProhibitioValue="userProhibitioValue"
        :isNo="isNo"
      />
    </div>

    <van-overlay :show="groupInfoVisible" @click.stop="" class="groupInfo_van-overlay">
      <GroupInfo :visible="groupInfoVisible" @close="groupInfoVisible = false" />
    </van-overlay>
    <van-overlay :show="personInfoVisible" @click.stop="" class="groupInfo_van-overlay">
      <PersonInfo :visible="personInfoVisible" @close="personInfoVisible = false" />
    </van-overlay>
    <van-overlay :show="store.state.imJs.retransmissionDlog" @click.stop="" class="groupInfo_van-overlay">
      <RetransmissionDlog/>
    </van-overlay>
  </div>
</template>
<script setup name="MsgDetails">
import RetransmissionDlog from "./retransmissionDlog.vue"
import PersonInfo from "./personInfo.vue";
import GroupInfo from "./groupInfo.vue";
import { chat } from "@/untils/tim";
import TencentCloudChat from "@tencentcloud/chat";
import { ref, onUpdated, computed, onMounted,watch } from "vue";
import { useRouter } from "vue-router";
import MessageSendBox from "./components/messageSendBox.vue";
import MessageContainer from "./components/messageContainer.vue";
import { useStore } from "vuex";
import { showSuccessToast, showFailToast } from "vant";
import { basicInfo, cancelFollow, addFollow } from "@/assets/api/user";
const store = useStore();
const userInfo = computed(() => store.state.userJs.userInfo);
const router = useRouter();
const imChatType = ref("");
const imChatId = ref("");
const userId = ref(0);
const allProhibitioValue = ref(false); //保存全体是否被禁言
const userProhibitioValue = ref(false); // //保存我是否被禁言
const groupInfoVisible = ref(false);
const personInfoVisible = ref(false);
const isNo = ref(false); // 是否是群成员
const isLogin = ref(localStorage.getItem("isLogin"));
function goLogin() {
  router.push({
    name: "Login",
  });
}
function _getAllProhibitioValue(v) {
  allProhibitioValue.value = v;
}
function _getUserProhibitioValue(v) {
  userProhibitioValue.value = v;
}
function goInfo() {
  if (imChatType.value == "GROUP") {
    if (isNo.value) {
      showFailToast("您已经不是该群的成员了");
      return;
    }
    groupInfoVisible.value = true;
    // router.push({
    //   //跳转群聊资料
    //   name: "GroupInfo",
    // });
  } else {
    personInfoVisible.value = true;
    // {
    //   router.push({
    //     //跳转个人资料
    //     name: "PersonInfo",
    //   });
    // }
  }
}
function followBtn() {
  addFollow({ uid: userId.value }).then((res) => {
    showSuccessToast("关注成功");
    basicUserInfo.value.isAttented = 1;
  });
}
function cancleFollow() {
  cancelFollow({
    uid: userId.value,
  }).then((res) => {
    showSuccessToast("已取消关注");
    basicUserInfo.value.isAttented = 0;
  });
}
const conversationProfileInfo = ref({}); //当前会话资料

const basicUserInfo = ref({}); //当前用户资料
//获取会话信息
function _getConversationProfile() {
  imChatType.value = localStorage.getItem("imChatType");
  imChatId.value = localStorage.getItem("imChatId");
  chat()
    .getConversationProfile(imChatType.value + imChatId.value)
    .then((imResponse) => {
      conversationProfileInfo.value = imResponse.data.conversation;
      if (imChatType.value == "GROUP") {
        getProhibitio();
        allProhibitioValue.value = imResponse.data.conversation.groupProfile.muteAllMembers;
      } else {
        userId.value = imResponse.data.conversation.userProfile.userID * 1;
        _basicInfo(imResponse.data.conversation.userProfile.userID * 1);
      }
    });
}
function _basicInfo(id) {
  basicInfo({ platform: 3, userId: id }).then((res) => {
    if (!res.data) return;
    basicUserInfo.value = res.data;
  });
}
function getProhibitio() {
  chat()
    .getGroupMemberProfile({
      //查询自己
      groupID: localStorage.getItem("imChatId"),
      userIDList: [userInfo.value.uid + ""], // 请注意：即使只拉取一个群成员的资料，也需要用数组类型，例如：userIDList: ['user1']
    })
    .then((res) => {
      // console.log(res);
    })
    .catch((err) => {
      // console.log(err);
      if (
        err == "Error: only group member can get group info" ||
        err == "Error: this group does not exist"
      ) {
        isNo.value = true;
      }
    });
}
function goback() {
  const history = router.options.history.back;

  if (history) {
    router.back();
  } else {
    router.push({
      name: "Msg",
    });
  }
}
onMounted(() => {
  if (
    localStorage.getItem("Token") &&
    isLogin &&
    localStorage.getItem("imChatType") &&
    localStorage.getItem("imChatId") &&
    store.state.imJs.sdkStateReadyStatus
  ) {
    _getConversationProfile();
  }
});
watch(
  () => store.state.imJs.sdkStateReadyStatus,
  (val) => {
    if (
      val &&
      localStorage.getItem("Token") &&
      isLogin &&
      localStorage.getItem("imChatType") &&
      localStorage.getItem("imChatId")
    ) {
      _getConversationProfile();
    }
  }
);
</script>

<style scoped>
.msg_details {
  position: fixed;
  top: 10.66vw;
  bottom: 16vw;
  left: 0;
  right: 0;
  background: #f3f5f6;
}
.contaier {
  height: 100%;
  overflow-y: scroll;
}

.head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10.66vw;
  display: flex;
  align-items: center;
  padding: 0 3.73vw;
  background-color: #fff;
}
.back_icon {
  width: 5.33vw;
  height: 5.33vw;
}
.msg_name {
  min-width: 60px;
  flex: 1;
  text-align: left;
  color: #000;
  font-family: PingFang SC;
  font-size: 4.26vw;
  font-weight: 500;
  margin-left: 3.73vw;
}
.right {
  display: flex;
  align-items: center;
}
.more {
  width: 6.4vw;
  height: 6.4vw;
}
.followed_btn {
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-style: normal;
  font-weight: 600;
  line-height: 3.73vw;
  border-radius: 6px;
  padding: 6px 10px;
  border: 0.5px solid #d1d5db;
  margin-right: 5.33vw;
  background: #fff;
}
.follow_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5.33vw;
  color: #fff;
  border-radius: 6px;
  background: #d8ad6d;
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 600;
  width: 14.93vw;
  height: 6.93vw;
}
.follow_btn img {
  width: 3.2vw;
  height: 3.2vw;
}

.send_box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.groupInfo_van-overlay {
  background-color: #fff !important;
}
.empty {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
}
.login-btn {
  color: #fff;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 4.26vw;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 42.66vw;
  height: 12.8vw;
  border-radius: 7.2vw;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(283deg, #d8ad6d 9.2%, #f5d6a8 90.8%);
}
</style>
