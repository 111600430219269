<template>
  <LoadingCom v-if="loading" />
  <div class="group_info" v-else>
    <div class="fixed">
      <div class="back_box">
        <img src="@/assets/img/icon/back.png" alt="" @click.stop="emit('close')" />
        <div class="title">群组{{ groupProfile.memberCount }}</div>
      </div>
    </div>
    <div class="container">
      <div class="members_box">
        <div class="members_list">
          <div class="members_item" v-for="(item, i) in memberList" :key="i">
            <div class="head">
              <img
                :src="filesCdnDomain + item.avatar"
                alt=""
                class="User_aravt"
                v-if="item.avatar"
              />
              <img v-else src="@/assets/img/icon/avart.png" alt="" class="User_aravt" />
              <img src="@/assets/img/icon/fg.png" alt="" class="icon" v-if="item.role == 'Admin'" />
              <img
                src="@/assets/img/icon/owner.png"
                alt=""
                class="icon"
                v-if="item.role == 'Owner'"
              />
            </div>
            <div class="name">{{ item.remark ? item.remark : item.nick }}</div>
          </div>
        </div>
        <div class="show_all" v-if="groupProfile.memberCount > memberList.length">
          <div @click="openGroupMembersBox">
            查看全部成员
            <img src="@/assets/img/icon/more.png" alt="" class="more_icon" />
          </div>
        </div>
      </div>
      <div class="info_box">
        <div class="info_item height flex">
          <div class="left_lable">群聊名称</div>
          <div class="right_icon" @click.stop="goEditNickName">
            <span> {{ groupProfile.name }} </span>
            <img src="@/assets/img/icon/more.png" alt="" class="more_icon" />
          </div>
        </div>
        <div class="info_item border height flex">
          <div class="left_lable">群头像</div>
          <div class="right_icon">
            <img
              :src="filesCdnDomain + groupProfile.avatar"
              alt=""
              class="group_head"
              v-if="groupProfile.avatar"
            />

            <img v-else src="@/assets/img/icon/avart.png" alt="" class="group_head" />

            <img src="@/assets/img/icon/more.png" alt="" class="more_icon" />
          </div>
        </div>
        <div class="info_item" @click="goNoticePage" style="margin-top: 3.73vw">
          <div class="top flex">
            <div class="left_lable">群公告</div>
            <div class="right_icon">
              <img src="@/assets/img/icon/more.png" alt="" class="more_icon" />
            </div>
          </div>
          <div class="notice">
            {{ groupProfile.notification ? groupProfile.notification : "暂无公告" }}
          </div>
        </div>
      </div>
      <div class="toping">
        <div class="info_item height flex">
          <div class="left_lable">置顶聊天</div>
          <div class="right_icon">
            <van-switch v-model="isPinned" active-color="#D8AD6D" @change="pinConversation" />
          </div>
        </div>
      </div>
      <div class="bottom_btn">
        <div class="btn_box clear" @click="clearSheet = true">清空聊天记录</div>
        <div class="btn_box" @click="logOutSheet = true">删除并退出</div>
      </div>
    </div>
    <van-action-sheet v-model:show="clearSheet" class="sheet">
      <div class="title">聊天记录删除后不可恢复，是否删除？</div>
      <div class="del_btn btn" @click="handleClearlog">删除</div>
      <div class="btn" @click="clearSheet = false">取消</div>
    </van-action-sheet>
    <van-action-sheet v-model:show="logOutSheet" class="sheet">
      <div class="title">确定要删除并退出群聊吗？</div>
      <div class="del_btn btn" @click="handleDelete">删除</div>
      <div class="btn" @click="logOutSheet = false">取消</div>
    </van-action-sheet>
   
      <GroupMembers
        @close="visible = false"
        :memberCount="groupProfile.memberCount"
        :visible="visible"
        ref="groupMembersRef"
      />
   
    <van-overlay :show="editNickNameVisible" @click.stop="">
      <EditNickName
        :name="groupProfile.name"
        :visible="editNickNameVisible"
        @close="closeEditNickNameVisible"
      />
    </van-overlay>
    <van-overlay :show="grounpNoticeVisible" @click.stop="">
      <GrounpNotice
        :notification="groupProfile.notification"
        :visible="grounpNoticeVisible"
        @close="closeGrounpNoticeVisible"
      />
    </van-overlay>
  </div>
</template>
<script setup name="GroupInfo">
import GrounpNotice from "./grounpNotice.vue";
import EditNickName from "./editNickName.vue";
import GroupMembers from "./groupMembers.vue";
import { chat } from "@/untils/tim";
import TencentCloudChat from "@tencentcloud/chat";
import { onMounted, ref, computed, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { showToast, showSuccessToast } from "vant";
import { useStore } from "vuex";
const emit = defineEmits(["close"]);
const store = useStore();
const filesCdnDomain = computed(() => store.state.commonJs.filesCdnDomain);
const router = useRouter();
const memberList = ref([]);
const groupProfile = ref({ memberCount: 0 });
const myIdentity = ref("");
const isPinned = ref(false);
const clearSheet = ref(false);
const logOutSheet = ref(false);
const loading = ref(true);
const visible = ref(false);
const editNickNameVisible = ref(false);
const grounpNoticeVisible = ref(false);
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});
function closeEditNickNameVisible() {
  editNickNameVisible.value = false;
}
function closeGrounpNoticeVisible() {
  grounpNoticeVisible.value = false;
}
function goEditNickName() {
  if (myIdentity.value == "Member") {
    showToast("管理员或群主才能修改群名称");
    return;
  }
  editNickNameVisible.value = true;
}
function goNoticePage() {
  if (myIdentity.value == "Member") {
    showToast("管理员或群主才能修改群公告");
    return;
  }
  grounpNoticeVisible.value = true;
}
function pinConversation(v) {
  chat()
    .pinConversation({
      conversationID: "GROUP" + localStorage.getItem("imChatId"),
      isPinned: v,
    })
    .then((res) => {
      showSuccessToast(v ? "已设置为置顶消息" : "已取消置顶");
    });
}
function handleClearlog() {
  chat()
    .clearHistoryMessage("GROUP" + localStorage.getItem("imChatId"))
    .then((res) => {
      showSuccessToast("已删除");
      clearSheet.value = false;
    });
}
function handleDelete() {
  if (myIdentity.value == "Owner") {
    //群主  ，解散群
    chat()
      .dismissGroup(localStorage.getItem("imChatId"))
      .then((imResponse) => {
        showSuccessToast("已解散群聊");
        logOutSheet.value = false;
        chat().deleteConversation("GROUP" + localStorage.getItem("imChatId")); //并删除会话
        router.push({
          name: "Msg",
        });
      });
  } else {
    ///其他身份，退出群聊
    chat()
      .quitGroup(localStorage.getItem("imChatId"))
      .then((imResponse) => {
        showSuccessToast("已退出群聊");
        logOutSheet.value = false;
        chat().deleteConversation("GROUP" + localStorage.getItem("imChatId")); //并删除会话
        router.push({
          name: "Msg",
        });
      });
  }
}
function _getGroupMemberList() {
  chat()
    .getGroupMemberList({
      groupID: localStorage.getItem("imChatId"),
      offset: 0,
      count: 24,
    })
    .then((imResponse) => {
      loading.value = false;
      memberList.value = imResponse.data.memberList;
        memberList.value.forEach((item) => {
        getMerberRemarkName(item.userID).then((res) => {
          let _memberList = res.data.friendList.length > 0 ? res.data.friendList[0] : {};
          // console.log(_memberList);
          item.remark = _memberList ? _memberList.remark : "";
        });
      });
    })
    .catch(() => {
      loading.value = false;
    });
}
function getMerberRemarkName(userID) {
  let promise = chat().getFriendProfile({
    groupID: localStorage.getItem("imChatId"),
    userIDList: [userID], // 请注意：即使只拉取一个群成员的资料，也需要用数组类型，例如：userIDList: ['user1']
  });

  return promise;
}
function _getGroupProfile() {
  chat()
    .getGroupProfile({
      groupID: localStorage.getItem("imChatId"),
    })
    .then((imResponse) => {
      groupProfile.value = imResponse.data.group;
      myIdentity.value = imResponse.data.group.selfInfo.role;
    });
}
function _getConversationProfile() {
  let imChatType = localStorage.getItem("imChatType");
  let imChatId = localStorage.getItem("imChatId");
  chat()
    .getConversationProfile(imChatType + imChatId)
    .then((imResponse) => {
      isPinned.value = imResponse.data.conversation.isPinned;
    });
}
const groupMembersRef = ref()
function openGroupMembersBox(){
  visible.value = true 
  if(groupMembersRef.value){

  
  groupMembersRef.value._getGroupMemberList()}
  console.log(groupMembersRef.value)
  
}
watchEffect(() => {
  if (props.visible) {
    setTimeout(function () {
      _getGroupMemberList();
      _getGroupProfile();
      _getConversationProfile();
    }, 1000);
  }
});
</script>
<style scoped>
.group_info {
  min-height: 100vh;
  background: #fafafa;
}
.container {
  margin-top: 10.66vw;
  padding: 10px;
}
.members_box {
  padding: 10px 0px;
  background: #fff;
  border-radius: 8px;
}
.members_list {
  display: flex;
  flex-wrap: wrap;
}
.members_item {
  flex-basis: 20%;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.head {
  width: 12.53vw;
  height: 12.53vw;
  position: relative;
}
.head .User_aravt {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.icon {
  position: absolute;
  width: 14px;
  height: 14px;
  bottom: 0;
  right: -2px;
}
.name {
  color: rgba(0, 0, 0, 0.45);
  font-family: PingFang SC;
  font-size: 3.2vw;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 12.53vw;
}

.show_all {
  padding: 10px 0 0;
  display: flex;
  justify-content: center;
}
.show_all div {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
}

.info_box {
  background: #fff;
  border-radius: 8px;
  margin-top: 10px;
  padding: 0 10px;
}
.left_lable {
  color: #222;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
}
.right_icon {
  display: flex;
  align-items: center;
}
.right_icon span {
  color: rgba(0, 0, 0, 0.45);
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
}
.group_head {
  width: 10.13vw;
  height: 10.13vw;
  border-radius: 50%;
  object-fit: cover;
}

.border {
  border-top: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
}
.height {
  height: 12.8vw;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notice {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.45);
  font-family: PingFang SC;
  font-size: 3.46vw;
  font-weight: 400;
  padding: 4px 0;
  text-align: left;
  padding-bottom: 3.73vw;
}
.toping {
  background: #fff;
  border-radius: 8px;
  margin: 10px 0;
  padding: 0 10px;
}
.bottom_btn {
  background: #fff;
  border-radius: 8px;
  padding: 0 10px;
}
.btn_box {
  height: 12.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ef4444;
  text-align: center;
  font-family: PingFang SC;
  font-size: 3.73vw;
  font-weight: 400;
}
.clear {
  border-bottom: 1px solid #fafafa;
}

.more_icon {
  width: 5.33vw;
  height: 5.33vw;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.sheet div {
  padding: 20px 0;
}
.title {
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
}
.del_btn {
  border-bottom: 8px solid #fafafa;
}
.btn {
  color: #222;
  text-align: center;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
}
.van-loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
